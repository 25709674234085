import {Link} from 'gatsby';
import * as React from 'react';

export const Navigation = () => (
  <ul className="main-navigation" type="navigation">
    <li><Link activeClassName="active" to={'/blog'}>Blog</Link></li>
    <li><Link activeClassName="active" to={'/references'}>Resources</Link></li>
    <li className="reverse"><Link activeClassName="active" to={'/'}> 🏡 </Link></li>
  </ul>
);
