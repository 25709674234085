import * as React from 'react';
import {Helmet} from 'react-helmet';
import {graphql, Link} from 'gatsby';

import '../styles.scss';
import {Navigation} from '../parts/navigation';
import {Footer} from '../parts/footer';
import {Wave} from '../parts/wave';



const IndexPage = (props) => {
  const {data} = props;
  const pageContents = data.allMarkdownRemark.edges.map(e => ({...e.node.frontmatter, ...e.id, ...e.node})).reverse();


  return (
    <main className="page-wrapper">
      <Helmet>
        <meta name="description" content="Kaloczi David's personal site."/>
        <title>David Kaloczi</title>
      </Helmet>
      <div className="page-section">

        <div className="page-main">
          <Navigation/>
        </div>
        <div className="page-main">
          <h1>
            My blog
          </h1>
          <p>
            I am not posting so frequently... if there is something meaningful, I'll share it here.
          </p>

          {pageContents.map((content, idx) => {
            return (
              <div key={idx} className="blog-list-item">
                <Link to={'/blog/'+content.title.replace(' ', '-').toLocaleLowerCase()}>
                <h3>{content.title}</h3>
                <p className="date">{content.date}</p>
                <p className="intro">{content.intro}</p>
              </Link>
              </div>

            );
          })}

        </div>
        <Wave waveNumber={3}/>
      </div>
      <Footer/>
    </main>
  );
};

export default IndexPage;


export const pageQuery = graphql`
 query MyQuery {
  allMarkdownRemark(filter: {frontmatter: {section: {eq: "blog"}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "YYYY MMMM DD")
          intro
        }
        html
      }
    }
  }
}


`;
